$white: #FFFFFF;
$grey: #F6F9FC;
$dark-grey: #808080;
$black: #303841;
$blue: #3D5AF1;

$colors: (white: #FFFFFF, grey: #E5E5E5, dark-grey: #808080, black: #303841, blue: #3D5AF1);

$bgColors: (white: #FFFFFF, blue: #3D5AF1);

@each $color, $value in $bgColors {
  .bg--#{$color} {
    background-color: $value; } }

@each $color, $value in $colors {
  .text--#{$color} {
    color: $value; } }

// Typography variables

$primary-font: Roboto;
$secondary-font: 'EB Garamond', serif;

$small-text: 0.85rem;
$standard-text: 1rem;
$big-text: 1.25rem;

@mixin font($size, $font-variant: 1, $weight: 400) {
  font-size: $size;
  font-weight: $weight;

  @if $font-variant != 1 {
    font-family: $secondary-font; } }

@mixin standard-font {
  @include font(1rem, 1, 400);

  @media (min-width: 768px) {
    font-size: 1.25rem; } }

.tl--70 {
  max-width: 70ch; }

$box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, .15);

// Breakpoints and layout widths

$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px);
$grid-breakpoints-names: (xs, sm, md, lg, xl, xxl);
$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1140px);

// Mixins goes here

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null); }

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content; } }
  @else {
    @content; } }
