// Scope variables for header


.header {
  position: fixed;
  padding: 1rem 0;
  width: 100%;
  z-index: 3;
  transition: all .3s;
  top: 0;
  background-color: $white;
  box-shadow: 0 4px 10px 0 rgba(0,0,0, .15);

  &__container {
    display: flex;
    justify-content: space-between; }

  &__collapse {
    fill: $blue;
    width: 30px;
    height: 30px;
    z-index: 4;
    position: relative; }

  &__nav {
    position: absolute;
    right: -100%;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: $white;
    transition: right .3s;

    &--is-opened {
      right: 0;

      body {
        overflow: hidden; } } }

  &--is-scrolled {
    box-shadow: $box-shadow;
    transition: background-color .3s;
    transition: top .4s ease-out; }

  &--is-hidden {
    top: -80px;
    transition: top .4s ease-out;
    transition-delay: .2s; }

  &__menu, .menu {
    display: block;
    padding: 8rem 2rem;
    animation: menu-open .3s;
    animation-fill-mode: forwards;

    &__item, a {
      text-decoration: none;
      text-align: right;
      color: $black;
      margin: 1rem 0;
      font-size: 1.75rem;
      text-transform: capitalize;
      font-weight: 500;

      &:hover {
        color: $blue; } }

    &__item {
      cursor: pointer;
      margin: 1rem 0; } } }

.nav {
  &__collapse {
    padding: 1rem 2rem 2rem;
    float: right; } }

.collapse {
  fill: $white;
  width: 30px;
  height: 30px;
  position: relative; }

@media (min-width: 768px) {
  .header {
    &__logo {
      width: 110px; } }

  .nav {
    &__item, a {
      font-size: 2.5rem; } }

  .collapse {
    width: 45px;
    height: 45px; } }

@media (min-width: 992px) {
  .header {
    padding: 1.2rem 0;

    &--is-hidden {
      top: -100px; }

    &__collapse {
      display: none; }

    &__nav {
      position: relative;
      right: 0;
      background-color: transparent;
      height: auto; }

    &__menu, .menu {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      padding: 0;
      height: 100%;
      align-items: center;

      &__item, a {
        margin-bottom: 0;
        font-size: 1.25rem; }

      &__item {
        margin-left: 2.5rem;
        margin-top: 0; } } } }

@media (min-width: 1200px) {
  .header {
    &__container {
      align-items: center;
      max-width: 100%; }

    &__nav {
      position: relative;
      right: 0;
      height: 100%;
      width: auto;
      background-color: transparent; }

    &__menu, .menu {
      display: flex;
      justify-content: flex-end;
      padding: 0;

      &__item, a {
        margin-bottom: 0;
        margin-left: 2rem;
        font-size: 1.25rem; } } } }
