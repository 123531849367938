.gallery {
  overflow: auto;
  -ms-overflow-style: hidden;
  padding: 2rem 0;

  &__container {
    display: flex;
    overflow: hidden;
    position: relative;
    -ms-overflow-style: auto;
    width: 100%; }

  &__fullscreen {
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, .65);
    display: none;

    &--is-visible {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; } }

  &__fullscreen-button {
    fill: $white;
    width: 40px;
    cursor: pointer; }

  &__fulscreen-buttons {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-bottom: 2rem; }

  &__fulscreen-wrapper {
    width: auto;
    height: auto;
    max-width: 90%;
    position: relative; }

  &__full-image {
    position: relative;
    width: auto;
    max-width: 100%;
    max-height: 75vh;
    object-fit: contain; }

  &__image {
    object-fit: cover;
    height: 250px;
    width: 180px;
    margin: 0 .35rem;
    border-radius: 10px;
    cursor: pointer;
    flex-shrink: 0; }

  &__navigation {
    grid-row-start: 3; }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    cursor: pointer; }

  &__button {
    width: 2.5rem;
    height: 2.5rem;
    background-color: $blue;
    margin-left: .75rem;

    svg {
      position: relative;
      width: 20px;
      height: 20px;
      display: block;
      margin: 0 auto;
      top: 50%;
      fill: $grey;
      transform: translateY(-50%); }

    &--rotated {
      transform: rotate(180deg); } }

  &__numbers {
    text-align: right;
    font-size: 2rem;
    margin-top: .5rem;
    font-family: $secondary-font;
    font-weight: 600; } }

@media (min-width: 768px) {
  .gallery {
    padding: 3rem 0;

    &__image {
      height: 400px;
      width: 300px; }

    &__button {
      width: 3rem;
      height: 3rem;

      svg {
        width: 25px;
        height: 25px; } }

    &__numbers {
      font-size: 2.25rem; } } }

@media(min-width: 1200px) {
  .gallery {
    padding: 0;
    grid-column: 2;
    grid-row: 1 / 3;

    &__image {
      height: 410px;
      width: 290px; }

    &__navigation {
      grid-column: 1;
      grid-row: 2;
      align-self: end; } } }
