.intro {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 0;
  display: flex;
  flex-direction: column;

  &__overlay {
    text-decoration: none;
    position: relative;
    width: 100%;
    height: 100px;
    flex-grow: 1;
    transition: all 1400ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      flex-grow: 2; } }

  &__button {
    z-index: 2;
    margin-top: 1rem; }

  &__logo {
    position: relative;
    z-index: 2;
    height: 7vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    padding: 0.125rem; }

  &__column {
    width: 100%;
    height: 100%;
    position: relative;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    h2 {
      font-size: 3rem;
      color: $white;
      text-decoration: none;
      z-index: 2;
      margin-bottom: 1rem; }

    p {
      z-index: 2; } }

  .column--1 {
    background-image: url(../assets/1.jpg);
    background-size: cover;
    background-position: center;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: rgba(0,0,0, .65); } }

  .column--2 {
    background-image: url(../assets/rims.jpg);
    background-size: cover;
    background-position: top right;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0, .45);
      z-index: 1; } } }

@media (min-width: 992px) {
  .intro {
    flex-direction: row;

    &__overlay {
      height: 100%;
      width: 300px; }

    &__column {
      h2 {
        margin-bottom: .5rem;
        font-size: 4rem; } }

    &__button {
      margin-top: 3rem; } } }
