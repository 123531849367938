@import "variables";
@import "layout";
@import "typography";
@import "button";
@import "form";
@import "gallery";
@import "footer";
@import "header";
@import "address";
@import "offer";
@import "intro";

html {
  scroll-behavior: smooth; }

body {
  font-family: Roboto;
  background-color: $grey; }

.CookieConsent {
  background-color: $blue !important;
  color: $white;
  p {
   font-size: 1rem; }

  a {
    color: $white;
    font-size: 1rem;
    margin-left: .25rem; }

  button {
    padding: .5rem .75rem !important;
    font-family: $primary-font;
    font-weight: 500; } }

.nomatch {
  align-items: center !important; }

.logo {
  width: 120px;
  z-index: 4; }

.hero {
  z-index: 2;
  position: relative;

  &--offer {
    margin-top: 70px; }

  &__title {
    font-size: 2.25rem;
    letter-spacing: .5px;
    line-height: 1.25;
    margin-bottom: 1.5rem; }

  &__text {
    margin-bottom: 2rem; } }

.link {
  text-decoration: none; }

.list {
  list-style-type: none; }

.image {
  position: relative;
  max-width: 100%;

  &--normalPadding {
    padding-top: 3rem;
    padding-bottom: 3rem; } }

// Section styles

.landing {
  height: 100vh;
  padding: 0;

  &--almet {
    background-image: url(../assets/slide.jpg);
    background-size: cover;
    background-position: top right; }

  &--almet2 {
    background-image: url(../assets/rims.jpg);
    background-size: cover;
    background-position: center center; }

  &--half {
    height: 50vh;

    .container {
      align-items: center;
      margin: 0 auto; } }

  &__container {
    display: grid;
    align-items: center; }

  &__contact {
    display: none; }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, .65);
    z-index: 1; } }

.about__container {
  display: grid;
  grid-gap: 3rem; }

.list {
  &--withNumbers {
    .list__item {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: baseline;
      margin: 1rem 0;
      text-decoration: none;

      a {
        text-decoration: none;
        color: $black;

        &:hover {
          color: $blue; } } } }

  &__number {
    margin-right: .5em;
    @include font(1.5rem, 2, 600); } }

.realisation {
  &__container {
    display: grid; } }

.contact {
  &__container {
    display: grid;
    grid-row-gap: 3rem; }

  &__address {
    display: grid;
    grid-row-gap: 1.5rem; } }

// Google map styles

.map {
  &__container {
    width: 100%;
    height: 100vw; }

  &__area {
    width: 100%;
    height: 100%; } }

@media (min-width: 768px) {
  .hero {
    &__title {
      width: 24ch;
      font-size: 2.75rem; }

    &__text {
      width: 42ch; } }

  .about__container {
    .hero__text {
      width: 100%; } }

  .contact {
    &__address {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 2.25rem; } }

  .list {
    &__number {
      margin-right: .5em;
      @include font(1.75rem, 2, 600); } } }

@media (min-width: 992px) {
  .logo {
    width: 150px; }
  .self-end {
    align-self: end; }

  .landing {
    background-position: center left;

    &__contact {
      display: grid;
      position: relative;
      grid-template-columns: repeat(3, 1fr);
      font-family: $secondary-font;
      font-weight: 600;
      padding-bottom: 3rem;
      z-index: 2; }

    &--half {
      .container {
        max-width: 900px; } } } }

@media (min-width: 1200px) {
  .hero {
    h1 {
      font-size: 3.5rem; } }

  .about {
    &__container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 5rem; } }

  .realisation {
    &__container {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 3rem; } }

  .contact {
    &__container {
      grid-template-columns: 500px 1fr;
      grid-column-gap: 5rem; } }

  .map {
    &__container {
      height: 100%;
      grid-area: 1/2/4/2; } }

  .collapse {
    display: none; }

  .list {
    &--withNumbers {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 3.5rem; } } }
