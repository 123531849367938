.address {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__wrapper {
    background-color: $white;
    width: 2.25rem;
    height: 2.25rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px; }

  &__icon {
    fill: $blue;
    width: 1.25rem;
    height: 1.25rem; }

  &__label {
    font-size: 1.125rem;
    margin-left: 1rem;
    color: $white; }

  &__text {
    margin-top: .75rem;
    font-size: 1.125rem;
    display: block;
    width: 100%;
    color: $white;
    text-decoration: none; } }

@media (min-width: 768px) {
  .addres {
    &__label {
      font-size: 1.25rem; } } }
