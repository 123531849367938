.button {
  @include font(1rem, 1, 500);
  padding: 1rem 2rem;
  display: inline-block;
  text-decoration: none;
  transition: background-color .3s;
  will-change: background-color;

  &--primary {
    color: $white;
    background-color: $blue;

    &:hover {
      background-color: darken($blue, 7%); } }

  &--secondary {
    color: $blue;
    background-color: $white;

    &:hover {
      background-color: darken($white, 7%); } } }

@media (min-width: 768px) {
  .button {
    padding: 1.25rem 2.25rem; } }
