.section {
  width: 100%;
  position: relative;
  padding: 4rem 0; }

.container {
  height: 100%;
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;

  &--grid {
    display: grid; } }

@each $name, $size in $container-max-widths {
  @include media-breakpoint-up($name) {
    .container {
      max-width: $size; } } }

.justify--end {
  justify-content: flex-end; }

@media (min-width: 768px) {
  .section {
    &--first {
      padding-top: 5rem;
      padding-bottom: 3rem; } } }

@media (min-width: 992px) {
  .container {
    &--fluid {
      max-width: 100%; }

    &--left {
      max-width: 100%;
      margin: 0 15px;
      padding-right: 0;
      padding-left: calc((100% - 960px) / 2 - 15px); } }

  .landing .container {
    align-items: end; }

  .col-2--md {
    grid-template-columns: 1fr 1fr; }

  .gap-md--3 {
    grid-column-gap: 3rem; } }

@media (min-width: 1200px) {
  .section {
    padding: 8rem 0; }

  .about .container {
    grid-column-gap: 5rem; } }
