.text {
  font-size: 1.125rem;
  line-height: 1.6;
  display: block;

  &--small {
    font-size: 1rem;
    max-width: 55ch; } }

.title {
  font-family: $secondary-font; }

@media (min-width: 768px) {
  .text {
    font-size: 1.25rem;

    &--small {
      font-size: 1rem; } } }
