@mixin input-layout {
  box-shadow: 0 4px 10px 0 rgba(0,0,0, .15);
  border-radius: 4px; }

.form {
  &__overflow {
    max-height: 1000px;
    transition: max-height .35s;
    overflow: hidden;

    &--hidden {
      max-height: 0px;
      transition: max-height .35s; } }

  &__group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;

    &--row {
      flex-direction: row;
      align-items: center;

      .form__label {
        margin-bottom: 0;
        margin-left: .75rem;

        a {
          color: $white;
          font-size: .9rem; } } } }

  &__label {
    color: $white;
    font-size: .9rem;
    margin-bottom: .5rem; }

  &__input, textarea {
    font-size: 1rem;
    font-family: inherit;
    padding: 1em;
    border: none;
    resize: none;
    @include input-layout; }

  &__button {
    display: block;
    width: 100%;
    border: none;
    text-align: center;
    font-weight: 600;
    @include input-layout; } }

@media (min-width: 768px) {
  .form {
    &__contact {
      padding-top: 5rem;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 2.5rem; } } }

@media (min-width: 1200px) {
  .form {
    &__contact {
      grid-column-gap: 3.5rem; } } }
