.offer-article {
  &__content {
    display: block;
    margin: 0 auto;

    p {
      font-size: 1.125rem;
      line-height: 1.6;
      display: block;
      margin-bottom: 4rem; }

    a {
      font-size: 1.125rem; }

    strong {
      font-size: 1.125rem;
      line-height: 1.6; }

    h2 {
      font-size: 2rem;
      letter-spacing: .5px;
      line-height: 1.25;
      margin-bottom: 1.25rem; }

    ol {
      list-style-type: decimal;

      li {
        font-size: 1.125rem;
        line-height: 1.6;
        margin: 1.25rem 0;

        ul {
          list-style-type: disc;
          margin-left: 1rem; } } } } }

@media (min-width: 992px) {
  .offer-article {
    &__content {
      max-width: 900px; } } }
